<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="unitTemplate.title"></app-i18n>
      </h2>
    </div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
      v-if="!projectId"
    >
    </TableFilter>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push(`/projects/${projectId}/unit-template/new`)"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#000"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td
                  class="text-center flex items-center justify-center"
                  id="ignore-5"
                >
                  <app-list-item-image
                    :value="row.images"
                  ></app-list-item-image>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'title') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'description') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'area') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'rooms') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'bathrooms') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterEnum(row, 'unitType') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterEnum(row, 'finish') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    :class="{
                      'text-theme-24': !row.garage,
                      'text-green-600': row.garage
                    }"
                  >
                    <CheckCircleIcon
                      v-if="row.garage"
                      class="w-4 h-4 mr-2 ml-2"
                    />
                    <XCircleIcon v-else class="w-4 h-4 mr-2 ml-2" />
                  </div>
                </td>
                <td class="table-report__action w-60">
                  <div class="flex justify-center items-center gap-4">
                    <router-link
                      class="text-edit dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                      :to="`/projects/${projectId}/unit-template/${row.id}/edit`"
                    >
                      <EditIcon class="w-4 h-4" />
                      {{ i18n('common.edit') }}
                    </router-link>
                    <!-- <router-link
                      class="
                        text-view
                        dark:text-theme-30
                        flex
                        items-center
                        gap-1
                        whitespace-nowrap
                      "
                      :to="`/unit-template/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" />{{ i18n('common.view') }}
                    </router-link> -->
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      @click="selectedId = row.id"
                      class="text-theme-24 flex items-center gap-1 whitespace-nowrap"
                    >
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!projectId">
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.template')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination?.limit"
          :rowsNumber="pagination?.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <DeleteModal item="template" @delete="doDestroy(selectedId)" />
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  name: 'table-view-page',
  components: {
    DeleteModal
  },
  props: {
    projectId: {
      type: String
    }
  },
  data() {
    return {
      selectedId: null,
      columns: [
        {
          name: 'image',
          field: 'image',
          label: 'unitTemplate.fields.image',
          align: 'center'
        },
        {
          name: 'title',
          field: 'title',
          label: 'unitTemplate.fields.name',
          align: 'center'
        },
        {
          name: 'description',
          field: 'description',
          label: 'unitTemplate.fields.description',
          align: 'center'
        },
        {
          name: 'area',
          field: 'area',
          label: 'unitTemplate.fields.area',
          align: 'center'
        },
        {
          name: 'rooms',
          field: 'rooms',
          label: 'unitTemplate.fields.rooms',
          align: 'center'
        },
        {
          name: 'bathrooms',
          field: 'bathrooms',
          label: 'unitTemplate.fields.bathrooms',
          align: 'center'
        },
        {
          name: 'unitType',
          field: 'unitType',
          label: 'unitTemplate.fields.unitType',
          align: 'center'
        },
        {
          name: 'finish',
          field: 'finish',
          label: 'unitTemplate.fields.finish',
          align: 'center'
        },
        {
          name: 'garage',
          field: 'garage',
          label: 'unitTemplate.fields.garage',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'template/list/rows',
      pagination: 'template/list/pagination',
      loading: 'template/list/loading'
    }),
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.name'),
          value: 'name',
          type: 'text'
        }
      ]
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'template/list/doFetch',
      doFetchNextPage: 'template/list/doFetchNextPage',
      doFetchPreviousPage: 'template/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'template/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'template/list/doChangePaginationPageSize',
      doDestroy: 'template/list/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      if (!row) return 'ـــ'
      const options =
        fieldName === 'finish'
          ? ['noFinish', 'semiFinish', 'lux', 'superLux', 'ultraLux']
          : ['apartment', 'villa', 'benthouse']

      const val = options.filter((el) => el === row[fieldName])

      return val.length ? val[0] : 'ـــ'
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.projectId
          ? {
              key: 'projectId',
              operator: 'equal',
              value: this.projectId
            }
          : this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy,
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

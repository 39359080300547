<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('project.view.title') }}
      </h2>
    </div>
    <div>
      <div class="grid grid-cols-12 gap-6 mt-5 relative">
        <div class="col-span-12">
          <div class="intro-y box col-span-12 2xl:col-span-6 h-full">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2
                class="font-medium text-base"
                :class="isRTL ? 'ml-auto' : 'mr-auto'"
              >
                {{ i18n('project.details.title') }}
              </h2>
            </div>
            <div
              style="width: 100%; height: 70vh"
              class="flex justify-center"
              v-if="loading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="record">
              <div class="p-5">
                <div class="flex justify-center items-center space-x-4">
                  <div
                    class="w-32 h-32 flex justify-center items-center"
                    v-for="(image, index) in record.images"
                    :key="index"
                  >
                    <img :src="image" alt="" data-action="zoom" />
                  </div>
                </div>
              </div>
              <div class="p-5">
                <div class="relative flex items-center">
                  <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
                    <div class="font-medium mb-1">
                      {{ i18n('project.fields.name') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenterLocalization(record, 'name') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 border-t">
                <div class="relative flex items-center">
                  <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
                    <div class="font-medium mb-1">
                      {{ i18n('project.fields.description') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenterLocalization(record, 'description') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 border-t">
                <div class="relative flex items-center">
                  <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
                    <div class="font-medium mb-1">
                      {{ i18n('project.fields.projectType') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ record.projectType }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 border-t">
                <div class="relative flex items-center">
                  <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
                    <div class="font-medium mb-1">
                      {{ i18n('project.fields.address') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenterLocalization(record.address, 'name') }}
                    </div>
                  </div>
                  <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
                    <div class="font-medium mb-1">
                      {{ i18n('project.fields.coordinates') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ `${record.address.lat}, ${record.address.long}` }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="p-5 border-t" v-if="record.sellingType !== 'auction'">
                <div class="grid grid-cols-12 items-center">
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.originalPrice') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{
                        formatPrice(presenterDecimal(record, 'originalPrice'))
                      }}
                    </div>
                  </div>
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.commission') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{
                        formatPrice(record.priceWithComm - record.sellingPrice)
                      }}
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-12 items-center mt-3">
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.sellingPrice') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{
                        formatPrice(presenterDecimal(record, 'sellingPrice'))
                      }}
                    </div>
                  </div>
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.vat') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{
                        formatPrice(record.priceWithVat - record.priceWithComm)
                      }}
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-12 items-center mt-3">
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.priceWithVat') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{
                        formatPrice(presenterDecimal(record, 'priceWithVat'))
                      }}
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="p-5 border-t">
                <div class="grid grid-cols-12 items-center">
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.country') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenter(record, 'country') }}
                    </div>
                  </div>
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.city') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenter(record, 'city') }}
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="p-5 border-t">
                <div class="grid grid-cols-12 items-center">
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.details.addedOn') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenterDate(record, 'createdAt') }}
                    </div>
                  </div>
                  <div class="ml-4 col-span-6">
                    <div class="font-medium mb-1">
                      {{ i18n('product.fields.clicks') }}
                    </div>
                    <div class="text-gray-600 dark:text-gray-300">
                      {{ presenterInteger(record, 'clicks') }}
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <table-view-page :projectId="id"></table-view-page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TableViewPage from '@/views/projects/projects-view-table-page.vue'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    [TableViewPage.name]: TableViewPage
  },
  computed: {
    ...mapGetters({
      record: 'project/view/record',
      loading: 'project/view/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'project/view/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
}
</script>
